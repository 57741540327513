<template>
  <footer class="bg-black text-white text-14 text-center leading-18">
    <div class="border-b border-grey-425 mx-14 md:mx-0 py-26">
      <div class="flex flex-nowrap justify-center">
        <a :href="config.soMeLinks.facebook" rel="noopener" target="_blank" title="Facebook" class="mx-26">
          <div class="square-20 bg-contain bg-center bg-no-repeat bg-svg-facebook-white"></div>
        </a>
        <a :href="config.soMeLinks.instagram" rel="noopener" target="_blank" title="Instagram" class="mx-26">
          <div class="square-20 bg-contain bg-center bg-no-repeat bg-svg-instagram-white"></div>
        </a>
        <a :href="config.soMeLinks.twitter" rel="noopener" target="_blank" title="Twitter" class="mx-26">
          <div class="square-20 bg-contain bg-center bg-no-repeat bg-svg-twitter-white"></div>
        </a>
      </div>
    </div>
    <div class="mx-10">
      <div class="mt-26">
        <span>Udgiver: </span>
        <div class="block md:inline font-bold">Aller Media</div>
      </div>
      <div class="mt-16">
        <span>Ansvarshavende chefredaktør: </span>
        <div class="block md:inline font-bold">
          <span>Niels Pinborg | </span>
          <a href="mailto:pinborg@sh.dk" class="text-blue-600">pinborg@sh.dk</a>
        </div>
      </div>
      <div class="mt-16">
        <span>Adresse: </span>
        <div class="inline">
          <div class="block md:inline font-bold">Havneholmen 33 | 1561 København V </div>
          <div class="block md:inline font-bold">CVR.: 43325612 | Tlf.: 72 34 20 00</div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap justify-center py-35 mx-10 leading-94">
      <NuxtLink to="/kontakt" class="text-blue-600 uppercase text-13 font-bold m-10">Kontakt</NuxtLink>
      <a href="https://www.allerservice.dk/brands/se-og-hor?utm_source=seoghoer.dk&utm_medium=owned&utm_campaign=aller_seoghoer_ao_conversion_abo&utm_term=footerlink" rel="noopener" target="_blank" class="text-blue-600 uppercase text-13 font-bold m-10">Køb abonnement</a>
      <a href="https://aller.dk/mediesalg/download-materialer" rel="noopener" target="_blank" class="text-blue-600 uppercase text-13 font-bold m-10">Annoncør</a>
      <button class="text-blue-600 uppercase text-13 font-bold m-10" @click="showCookiePrompt">Administrer samtykke</button>
      <a href="https://aller.dk/privatlivspolitik/" rel="noopener" target="_blank" class="text-blue-600 uppercase text-13 font-bold m-10">Privatliv</a>
      <a href="https://aller.dk/ophavsret/" rel="noopener" target="_blank" class="text-blue-600 uppercase text-13 font-bold m-10">Ophavsret</a>
    </div>
  </footer>
</template>

<script setup lang="ts">
const { showCookiePrompt } = useAllerCookiebot();
const { public: config } = useRuntimeConfig();
</script>
